import React, { useEffect } from "react"
import { navigate, PageProps } from "gatsby"
import axios from "axios"
import { useQuery } from "react-query"
import { Center, Text } from "@chakra-ui/layout"
import { Spinner } from "@chakra-ui/spinner"
import { useLocation } from "@reach/router"

import SEO from "src/components/SEO"
import { useLocalStorageState } from "src/gatsby/useSessionStorageState"
import Layout from "src/components/Layout"
import Contact from "src/components/Contact"
import { Boat } from "src/lib/types/boat"
import { Offer } from "src/lib/types/offer"
import { useDripTrack } from "src/lib/hooks/useDripTrack"
import { boatDripData } from "src/lib/helpers/drip-data"

const Reserve = (props: PageProps<{ boatUid: string }>) => {
  const { boatUid } = props.params
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const boatParams = Object.fromEntries(new URLSearchParams(location.search))
  const offerId = boatParams.offerId

  const fetchBoat = () => axios.get<Boat>("/api/boats-get", { params: { uid: boatUid } })
  const { data: boatResponse, isLoading } = useQuery(["fetchBoat", boatUid], fetchBoat, {
    staleTime: 6 * 60 * 60 * 1000,
    enabled: !!boatUid,
  })
  const boat = boatResponse?.data

  const fetchOffer = () => axios.get<Offer>("/api/offers-get", { params: { id: offerId } })
  const { data: offerResponse, isLoading: offerIsLoading } = useQuery(["fetchOffer", offerId], fetchOffer, {
    staleTime: 6 * 60 * 60 * 1000,
    enabled: !!offerId && !!boatResponse?.data,
  })
  const offer = offerResponse?.data
  const [search] = useLocalStorageState("searchParams")

  useDripTrack("Visit boat reserve", boatDripData(boat, offer, search), { enabled: !!boat && !!offer })
  useEffect(() => {
    if (isLoading || offerIsLoading) return
    if (!boat) {
      navigate("/")
    }
  })

  return (
    <Layout invertedNav noHero>
      {isLoading ? (
        <Center h={400} w="100%">
          <Spinner />
        </Center>
      ) : !boat ? (
        <Center h={400} w="100%">
          <Text>Boat not found</Text>
        </Center>
      ) : (
        <>
          <SEO title={`${boat.modelName} | Reserve your dream boat`} noindex nofollow />
          <Contact boat={boat} offer={offer} type="reserve" search={search} />
        </>
      )}
    </Layout>
  )
}

export default Reserve
